import styled from 'styled-components';
import {GatsbyImage} from "gatsby-plugin-image"
import UniversalLink from '../../components/UniversalLink';
import breakpoint, {map} from 'styled-components-breakpoint';

const bottomSpacing = '14rem';

export const WorkList = styled.div`
    width: 100vw;
    padding-right: ${props => `calc(${props.theme.header.headerClosedWidth} + ${props.theme.spacing.root})`};
    margin-left: 50%;
    margin-right: 50%;
    transform: translateX(-50%);

    ${breakpoint('xl')`
        width: ${props => `${props.theme.widths.breakoutSizes.xl}`};
        margin-left: 0;
        margin-right: 0;
        transform: translateX(0);
    `}
`;

export const ImageWrapper = styled.div`
    position: absolute;
    top: -12rem;
    bottom: 0;
    width: 100%;
    overflow: hidden;
    pointer-events: none;
`;

export const WorkImage = styled(GatsbyImage)`
    border-top-right-radius: 140px;
    position: absolute !important;
    z-index: 1;
    top: 12rem;
    left: 0;
    transition: transform 0.2s;
    pointer-events: auto;
`;
export const WorkMeta = styled.div`
    position: relative;
    z-index: 10;
    padding-top: 2em;
    padding-bottom: calc(${bottomSpacing} + 0.75em);
    
    .inner-meta {
        width: 100%;
        ${props => map(props.theme.blockPadding, padding => `padding-left: ${padding}px; padding-right: ${padding}px;`)}
        ${breakpoint('xs', 'xl')`
            max-width: 1200px;
            margin-left: auto;
            margin-right: auto;
        `}
    }
    &:after {
        content: '';
        position: absolute;
        display: block;
        bottom: 0;
        left: 0;
        z-index: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(to bottom, rgba(12, 10, 11, 0), rgba(12, 10, 11, 0.7) 27%, ${props => props.theme.body.backgroundColor} 75%);
    }
`;

export const WorkClient = styled.span`
    transition: transform 0.2s;
    position: relative;
    z-index: 5;
    text-transform: uppercase;
    font-weight: 700;
    color: ${props => props.theme.colors.secondary};
`;

export const WorkTitle = styled.h5`
    margin-bottom: 0;
    position: relative;
    z-index: 5;
    font-size: ${props => props.theme.text.medium.fontSize};
    ${map({xs: '694px', lg: '974px'}, width => `max-width: ${width};`)}
    color: ${props => props.theme.colors.highlight};
`;

export const Work = styled(UniversalLink)`
    display: block;
    position: relative;
    margin-bottom: -${bottomSpacing};
    min-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    z-index: 5;
    ${WorkClient} {
        opacity: 0;
    }
    &:hover {
        ${WorkClient} {
            opacity: 1;
        }
        ${WorkImage} {
            transform: translateY(-12rem);
        }
        ${WorkTitle} {
            font-size: ${props => props.theme.text.large.fontSize};
        }
    }
`;
