import styled from 'styled-components';
import BlockLayout from '../BlockLayout';
import { Block, Children, InnerBlock } from '../BlockLayout/style';
import breakpoint, {map}  from 'styled-components-breakpoint'
const handleLayout = columnLayout => {
    switch(columnLayout) {
        case "1-2":
            return `
                flex: 1 1;
                &:last-child {
                    flex: 2 2;
                }
            `;
        case "2-1":
            return `
                flex: 1 1;
                &:first-child {
                    flex: 2 2;
                }
            `;
        default:
            return 'flex: 1 1;'
    }
} 
export const Columns = styled(BlockLayout).attrs(props => ({
        alignment: props.alignment || 'flex-start',
        columnLayout: props.columnLayout || 'equal',
    }))`
    > ${InnerBlock} {
       > ${Children}{
            display: flex;
            align-items: ${props => props.alignment};
            margin-left: -${props => props.theme.spacing.root};
            margin-right: -${props => props.theme.spacing.root};
            ${props => map(props.theme.blockPadding, padding => `margin-right: -${padding}px; margin-left: -${padding}px;`)}
            flex-direction: column;
            ${breakpoint('md')`
                flex-direction: row;
            `}
            > div {
                width: 100%;
                ${breakpoint('md')`
                    ${props => handleLayout(props.columnLayout)}
                `}
            }
        }
        ${InnerBlock}{
            padding-right: 0;
        }
    }
    ${Block} {
        width: 100%;
    }
`;