import React from "react";
import ModalConsumer from '../../Context/ModalContext';
import {Modal as StyledModal, ModalInner, CloseToggle} from './style';
const Modal = () => {
    return <ModalConsumer>
        {({ closeModal, modalIsOpen, modalContent}) => {
            return <>
            {modalIsOpen && <StyledModal>
                <CloseToggle onClick={() => closeModal()} aria-label="close">&#10005;</CloseToggle>
                    <ModalInner>{modalContent}</ModalInner>
                </StyledModal>}
            </>
        }}
    </ModalConsumer>
}

export default Modal