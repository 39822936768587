import styled from 'styled-components'
import { map } from 'styled-components-breakpoint';

export const PlayText = styled.span`
    text-transform: uppercase;
`;
export const InlineText = styled.div`
    vertical-align: middle;
    line-height: 1;
    color: ${props => props.theme.colors.secondary};
    font-size: ${props => props.theme.text.small.fontSize};
    font-weight: ${props => props.theme.text.small.fontWeight};
    ${props => map(props.theme.text.small.fontSizes, size => `font-size: ${size};`)}
    margin-top: 1.5626em;
    &:before {
        content: '\u2192';
        display: inline-block;
        margin-right: 0.5em;
    }
`;