import React from "react";
import { getImage } from "gatsby-plugin-image";
import { Client as StyledClient, ClientImage, ClientImageWrapper, ClientName } from "./style";
import UniversalLink from "../UniversalLink";

export const Client = ({ client, ...other}) => {
  const image = client.featuredImage
    ? getImage(client.featuredImage.node.localFile)
    : false;
  const work = client?.relatedContent?.clientWorkRelationship;
  const link = client?.workLink?.workLink?.uri;
  return (
    <StyledClient {...other}>
      <UniversalLink to={link ? link : work && work[0].uri}>
        {image && <ClientImageWrapper>
          <ClientImage image={image} />
        </ClientImageWrapper>}
        <ClientName>{client.title}</ClientName>
      </UniversalLink>
    </StyledClient>
  );
};
