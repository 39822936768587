import React from "react";
import BlockLayout from '../BlockLayout'
import {Wysiwyg as StyledWysiwyg} from "../../components/Wysiwyg"
import {ListItem, ListIcon, ListWrapper} from './style'

const IconList = ({heading, settings, items, className}) => {
    const Items = items ? items.map((item, key) => {
        return <ListItem key={key}>
            {item?.icon?.localFile && <ListIcon>
                <img src={item.icon.localFile.publicURL} alt="icon" />
            </ListIcon>}
            <StyledWysiwyg content={item.content} />
        </ListItem>
    }) : null;
    return <BlockLayout className={className} heading={heading} settings={settings}>
        <ListWrapper>    
            {Items}
        </ListWrapper>
    </BlockLayout>;
}
export default IconList