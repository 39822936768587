import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Socials from "../Socials";
import { Footer as StyledFooter, SubscribeWrapper, Column, Row, Copyright } from "./style";
import { StaticImage } from "gatsby-plugin-image"
import SubscribeForm from "../SubscribeForm";
import FooterMenu from "./FooterMenu";

const Footer = () => {
  const {
    wp: { allFooter },
  } = useStaticQuery(graphql`
    {
      wp {
        allFooter {
          countryAcknowledgement
          inclusiveStatement
          subscribeDescription
          subscribeTitle
        }
      }
    }
  `);
  return (
    <StyledFooter>
      <SubscribeWrapper>
        <Column>
          <h6 className="small">{allFooter.subscribeTitle}</h6>
          <p>{allFooter.subscribeDescription}</p>
        </Column>
        <Column>
          <SubscribeForm />
        </Column>
      </SubscribeWrapper>
      <Row>
        <Column>
          <StaticImage src="../../assets/images/indigenous-flag.png" alt="indigenous flag" height={24} />
          <StaticImage src="../../assets/images/tsi-flag.png" alt="torres strait islander flag" height={24} style={{marginLeft: '12px' }} />
          <p className="extraSmall">{allFooter.countryAcknowledgement}</p>
          <Copyright>
            © 2021 Bolster. All rights reserved.
            <FooterMenu className='footer-menu' vertical={false} style={{display: 'inline-block'}} />
          </Copyright>
        </Column>
        <Column>
          <Row space='20px'>
            <Column className='col-auto'>
              <StaticImage src="../../assets/images/inclusive-flags.png" alt="inclusive flag"  height={24} />
            </Column>
          </Row> 
          <Row space='20px'>
            <Column className='col-auto'>
              <p className="extraSmall">{allFooter.inclusiveStatement}</p>
            </Column>
            <Column>
              <Socials className='col-auto' align='flex-end' />
            </Column>
          </Row>
        </Column>
      </Row>
    </StyledFooter>
  );
};
export default Footer;
