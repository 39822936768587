import styled from 'styled-components';
import { Swiper } from "swiper/react";

export const WorkSlider = styled(Swiper)`
        overflow: visible;
        .swiper-slide {
            height: auto;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
        }
`;