import styled from 'styled-components'

export const ListIcon = styled.div`
    max-height: 2em;
    max-width: 2em;
    margin-right: 1em;
    img {
        height: 2em;
        width: 2em;
    }
`;

export const ListItem = styled.div`
    display: flex;
    align-items: center;
    margin: 2rem auto;
    ${ListIcon} {
        flex: 2em;
    }
    p{
        &:last-of-type {
            margin-bottom: 0;
        }
    }
`;
export const ListWrapper = styled.div`
    ${ListItem} {
        &:first-of-type {
            margin-top: 0;
        }
    }
`;