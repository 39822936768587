import React from "react";
import { Button } from "../../components/Button/style";
import BlockLayout from "../BlockLayout";

const CallToAction = (props) => {
  const { settings, wysiwyg, cta, className } = props;
  return (
    <BlockLayout className={className} heading={false} settings={settings}>
      <div dangerouslySetInnerHTML={{ __html: wysiwyg }} />
      <div style={{ textAlign: "center" }}>
        {cta && <Button to={cta.url} target={cta.target}>{cta.title}</Button>}
      </div>
    </BlockLayout>
  );
};
export default CallToAction;
