import React from "react";
import { getImage } from "gatsby-plugin-image";
import {Work, WorkList as StyledWorkList, WorkImage, ImageWrapper, WorkMeta, WorkClient, WorkTitle} from './style'

const WorkList = ({work, ...other}) => {
    const workList = work.map((item, index) => {
      const { relatedContent, uri } = item;
      const landscapeImage = item.pageOptions?.landscapeImage;
      const image = landscapeImage ? getImage(landscapeImage.localFile) : item?.featuredImage?.node ? getImage(item.featuredImage.node.localFile) : false;
        return (
          <Work key={index} to={uri}>
            {image && <ImageWrapper>
              <WorkImage image={image} alt={item.title} />
            </ImageWrapper>}
            <WorkMeta>
              <div className='inner-meta'>
                {relatedContent?.clientWorkRelationship && relatedContent.clientWorkRelationship[0] && <WorkClient>{relatedContent.clientWorkRelationship[0].title}</WorkClient>}
                <WorkTitle >{item.title}</WorkTitle>
                </div>
            </WorkMeta>
          </Work>
        );
      });
    return  <StyledWorkList {...other}>{workList}</StyledWorkList>;
}
export default WorkList