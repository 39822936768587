import styled from 'styled-components'
import BlockLayout from '../BlockLayout/index.js'
import { InnerBlock } from '../BlockLayout/style.js';
import {map} from 'styled-components-breakpoint';

export const StyledHero = styled(BlockLayout)`
    height: 100vh;
    display: flex;
    flex-direction: column;
    padding-bottom: 0;
    margin-bottom: 128px;
    ${props => map(props.theme.blockPadding, padding => `margin-bottom: ${padding * 4}px;`)}

    ${InnerBlock} {
        margin-top: auto;
        position: relative;
        padding-bottom: ${props => props.theme.spacing.root};
        * {
            position: relative;
            z-index: 1;
        }
        &:after {
            content: ' ';
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            height: 110%;
            min-height: 40vh;
            width: 110vw;
            background: ${props => props.theme.gradients.blackToBottom};
            transition: opacity 0.2s;
            z-index: 0;
        }
    }
`;

export const VideoEmbed = styled.div`
    position: relative;
    padding-top: 56.25%;
    .react-player {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
    }
`;