import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Icons from '../../constants/socialIcons';
import {IconLink, Menu, Nav} from './style'
const Socials = (props) => {
  const { wp } = useStaticQuery(graphql`
    {
      wp {
        allSocials {
          apple_music
          email
          facebook
          instagram
          snapchat
          soundcloud
          spotify
          twitter
          youtube
          medium
        }
      }
    }
  `);

  if (!wp?.allSocials) return null;
  let socials = [];
  for (const [i, value] of Object.entries(wp.allSocials)) {
      if(value) {
        const Icon = Icons[i];
        socials.push(
            <li key={i + value}>
              <IconLink href={i === 'email' ? `mailto:${value}` : value} target="_blank" rel="noreferrer" title={i}>
                <Icon />
              </IconLink>
            </li>
          );
      }
  }
  return (
    <Nav
      aria-label="Horizontal"
      role="navigation"
      {...props}
    >
      <Menu>{socials}</Menu>
    </Nav>
  );
};

export default Socials;
