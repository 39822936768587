import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Caption } from "./style";

const Image = ({ image, alt = "", ...other }) => {
  const imageFile = image?.localFile ? getImage(image.localFile) : false;
  if (imageFile) {
    return (
      <div>
        <GatsbyImage
          image={imageFile}
          alt={alt ? alt : image.altText}
          quality="70"
          {...other}
        />
        {image.caption && (
          <Caption dangerouslySetInnerHTML={{ __html: image.caption }} />
        )}
      </div>
    );
  }
  if (image?.localFile?.publicUrl) {
    return (
      <div>
        <img
          src={image.localFile.publicUrl}
          alt={alt ? alt : image.altText}
          {...other}
        />
        {image.caption && (
          <Caption dangerouslySetInnerHTML={{ __html: image.caption }} />
        )}
      </div>
    );
  }
  if (image?.sourceUrl) {
    return (
      <div>
        <img src={image.sourceUrl} alt={alt ? alt : image.altText} {...other} />
        {image.caption && (
          <Caption dangerouslySetInnerHTML={{ __html: image.caption }} />
        )}
      </div>
    );
  }
  if (image?.url) {
    return (
      <div>
        <img src={image.url} width={image.width} height={image.height} alt={alt ? alt : image.altText} {...other} />
        {image.caption && (
          <Caption dangerouslySetInnerHTML={{ __html: image.caption }} />
        )}
      </div>
    );
  }
  return null;
};
export default Image;
