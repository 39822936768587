import React from "react";
import BlockLayout from '../BlockLayout'
import SwiperCore, { Keyboard, Navigation, Mousewheel } from "swiper";
import { SwiperSlide } from "swiper/react";
import { Client } from "../../components/ClientLink";
import { ClientSlider } from "./style";

const ClientList = ({heading, settings, clients, className}) => {
  SwiperCore.use([Keyboard, Navigation, Mousewheel]);
    const Slides = clients.map((slide, index) => {
        return (
          <SwiperSlide key={index}>
              <Client client={slide} />
          </SwiperSlide>
        );
      });
    return <BlockLayout className={className} heading={heading} settings={settings}>
        <ClientSlider
        spaceBetween={24}
        slidesPerView='auto'
        mousewheel={{
          'forceToAxis' : true
        }}
        keyboard={{
          enabled: true,
          onlyInViewport: true,
        }}>
            {Slides}
        </ClientSlider>
    </BlockLayout>;
}
export default ClientList