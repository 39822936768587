import { HeroVideo, CallToAction, ClientList, Gallery, Wysiwyg, Work, Columns, Media, IconList, BlockQuote } from '../blocks'
const blocks = {
    WpContentBlocks_Contentblocks_Blocks_CallToAction: CallToAction,
    WpContentBlocks_Contentblocks_Blocks_Columns_columns_Blocks_CallToAction: CallToAction,
    WpContentBlocks_Contentblocks_Blocks_ClientList: ClientList,
    WpContentBlocks_Contentblocks_Blocks_GallerySlider: Gallery,
    WpContentBlocks_Contentblocks_Blocks_Wysiwyg: Wysiwyg,
    WpContentBlocks_Contentblocks_Blocks_Columns_columns_Blocks_Wysiwyg: Wysiwyg,
    WpContentBlocks_Contentblocks_Blocks_HeroVideo: HeroVideo,
    WpContentBlocks_Contentblocks_Blocks_WorkList: Work,
    WpContentBlocks_Contentblocks_Blocks_Columns: Columns,
    WpContentBlocks_Contentblocks_Blocks_Columns_columns_Blocks_Media: Media,
    WpContentBlocks_Contentblocks_Blocks_Media: Media,
    WpContentBlocks_Contentblocks_Blocks_Columns_columns_Blocks_Iconlist: IconList,
    WpContentBlocks_Contentblocks_Blocks_Iconlist: IconList,
    WpContentBlocks_Contentblocks_Blocks_Blockquote: BlockQuote,
}
export default blocks