import styled from 'styled-components';
import ReactPlayer from 'react-player';
import { GatsbyImage } from 'gatsby-plugin-image';
import PlayText from '../PlayText';
export const StyledReactPlayer = styled(ReactPlayer);

export const Poster = styled(GatsbyImage)(
    ({playing}) => `
    transition: opacity 0.2s;
    opacity: ${playing ? '0' : '1' };
    `)
export const PlayingText = styled(PlayText)``

export const VideoEmbed = styled.div`
    position: relative;
    padding-top: ${props => props.aspectRatio ? props.aspectRatio * 100 : '56.25'}%;
    .react-player {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
    }
    ${PlayingText} {
        position: absolute;
        bottom: 1em;
        left: 1em;
        z-index: 5;
        ${props => props.playing ? `display:none` : ``}
    }
    ${Poster} {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 2;
    }
`;
