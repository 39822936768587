import styled from 'styled-components';
import { map } from 'styled-components-breakpoint';

export const Content = styled.div`
    max-width: ${props => props.theme.widths.content};
    ${props => map(props.theme.widths.contentSizes, width => `max-width: ${width};`)}
    pre {
        white-space: pre-wrap;
    }
    a {
        &:link {
            border-bottom: 1px solid currentColor;
        }
    }
`;