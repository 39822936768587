import React from "react";
import {PlayText as PlayTextStyled, InlineText} from './style'

const PlayText = ({children, ...other}) => {
    return <PlayTextStyled {...other}>
        <InlineText>
            {children}
        </InlineText>
    </PlayTextStyled>
}

export default PlayText;