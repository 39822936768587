import React from 'react';
import UniversalLink from '../UniversalLink';
import { WorkImage, WorkTitle, ImageWrapper, ClientLabel } from './style';

const WorkCard = ({post: {title, featuredImage, uri, relatedContent}, imageRatio = false, ...other}) => {
    const image = featuredImage?.node ? featuredImage.node : featuredImage ? featuredImage : null;
    return <UniversalLink to={uri} {...other}>
        {featuredImage && <ImageWrapper ratio={imageRatio}><WorkImage image={image} alt={title} /></ImageWrapper>}
        {relatedContent?.clientWorkRelationship && relatedContent.clientWorkRelationship[0] && <ClientLabel>{relatedContent.clientWorkRelationship[0].title}</ClientLabel>}
        <WorkTitle>{title}</WorkTitle>
    </UniversalLink>
}
export default WorkCard;