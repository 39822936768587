import React, { useEffect, useState } from "react";
import HeaderMenu from "./HeaderMenu";
import { Header as StyledHeader, Icon, MenuToggle, MenuBlock } from "./style";
import BolsterB from "../../assets/svgs/bolster.inline.svg";
import BolsterO from "../../assets/svgs/bolster-o.inline.svg";
import UniversalLink from "../UniversalLink";

const Header = ({ headerPosition }) => {
  const [scrolling = false, setScrolling] = useState();
  const [scrollTop, setScrollTop] = useState(0);
  const [menuActive = false, setMenuActive] = useState();
  useEffect(() => {
    const onScroll = (e) => {
      setScrollTop(e.target.documentElement.scrollTop);
      setScrolling(window.outerHeight / 2 < scrollTop);
    };
    window.addEventListener("scroll", onScroll, { passive: true });
    return () =>
      window.removeEventListener("scroll", onScroll, { passive: true });
  }, [scrollTop]);

  return (
    <StyledHeader headerPosition={headerPosition} collapse={scrolling}>
      <UniversalLink to="/" aria-label="Home">
        <Icon collapse={scrolling}>
          {scrolling ? (
            <BolsterO className="logo" />
          ) : (
            <BolsterB className="logo" />
          )}
        </Icon>
      </UniversalLink>
      <MenuBlock active={menuActive} collapse={scrolling}>
        <MenuToggle
          active={menuActive}
          onClick={() => setMenuActive(!menuActive)}
        />
        <HeaderMenu className="header-menu" vertical={scrolling} />
      </MenuBlock>
    </StyledHeader>
  );
};
export default Header;
