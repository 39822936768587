import React, { useState } from "react";
import SwiperCore, { Keyboard, Navigation, Mousewheel } from "swiper";
import { SwiperSlide } from "swiper/react";
import WorkCard from "../WorkCard";
import { WorkSlider } from "./style";

const RelatedWork = ({work}) => {
  const [imageRatio, setRatio] = useState(0);
  SwiperCore.use([Keyboard, Navigation, Mousewheel]);
  const Slides = work.map((slide, index) => {
    const dimensions = slide?.featuredImage?.node?.mediaDetails;
    if(dimensions?.height && dimensions?.width){
      let ratio = dimensions.height / dimensions.width;
      
      if(ratio > imageRatio) {
          setRatio(ratio)
      }
    }
    return (
      <SwiperSlide key={index}>
        <WorkCard post={slide} imageRatio={imageRatio} />
      </SwiperSlide>
    );
  });

  return (
      <WorkSlider
        breakpoints={{
          414: {
            slidesPerView: 2.2,
          },
          768: {
            slidesPerView: 3.3,
          },
          1400: {
            slidesPerView: 4,
          }
        }}
        spaceBetween={16}
        slidesPerView={1.1}
        mousewheel={{
          forceToAxis: true,
        }}
        keyboard={{
          enabled: true,
          onlyInViewport: true,
        }}
      >
        {Slides}
      </WorkSlider>
  );
};

export default RelatedWork;
