import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Menu from "../Menu"
import Socials from "../Socials"
import { MenuWrapper } from "./style"

const HeaderMenu = (props) => {
  const { wpMenu } = useStaticQuery(graphql`
    {
      wpMenu(locations: {eq: PRIMARY_NAVIGATION}) {
        name
        menuItems {
          nodes {
            ...MenuItem
          }
        }
      }
    }
  `)

  if (!wpMenu?.menuItems?.nodes || wpMenu.menuItems.nodes === 0) return null

  return <MenuWrapper {...props}>
    <Menu menu={wpMenu} className='primary-menu' {...props}/>
    <Socials className="socials" />
  </MenuWrapper>
}

export default HeaderMenu