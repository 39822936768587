import React from "react";
import SwiperCore, { Keyboard, Navigation, Mousewheel } from "swiper";
import { SwiperSlide } from "swiper/react";
import Media from "../../components/Media";
import { StyledBlockLayout, GallerySlider, InnerSlide, NavigationContainer } from "./style";

import "swiper/swiper.scss";

const Gallery = (props) => {
  SwiperCore.use([Keyboard, Navigation, Mousewheel]);
  const { heading, settings, backgroundImage, className, gallery } = props;
  const Slides = gallery.map((slide, index) => {
    return (
      <SwiperSlide key={index}>
        <InnerSlide>
          <Media {...slide}/>
        </InnerSlide>
      </SwiperSlide>
    );
  });
  return (
    <StyledBlockLayout
      heading={heading}
      settings={settings}
      className={className}
      backgroundImage={backgroundImage?.localFile ? backgroundImage.localFile: false }
    >
      <GallerySlider
      breakpoints={{
        576: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 3,
        }
      }}
        spaceBetween={32}
        slidesPerView={1}
        mousewheel={{
          'forceToAxis' : true
        }}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
        keyboard={{
          enabled: true,
          onlyInViewport: true,
        }}
      >
        {Slides}
        <NavigationContainer>
            <div className="swiper-button-next" />
            <div className="swiper-button-prev" />
        </NavigationContainer>
      </GallerySlider>
    </StyledBlockLayout>
  );
};
export default Gallery;
