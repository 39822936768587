import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import { Nav as SocialsNav } from "../Socials/style";

export const MenuToggle = styled.span`
  display: block;
  width: 40px;
  height: 40px;
  position: absolute;
  top: calc(${(props) => props.theme.spacing.root} / 2);
  right: calc(${(props) => props.theme.spacing.root} / 2);
  transition: 0.2s;
  margin-left: auto;
  cursor: pointer;
  &:before,
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: currentColor;
    transform-origin: 50% 50%;
    transition: 0.2s;
  }
  &:before {
    top: 50%;
    transform: ${(props) =>
      props.active
        ? `rotate(-45deg) translateY(-1px)`
        : `rotate(0) translateY(-8px)`};
  }
  &:after {
    bottom: 50%;
    transform: ${(props) =>
      props.active
        ? `rotate(45deg) translateY(1px)`
        : `rotate(0) translateY(8px)`};
  }
`;

export const Header = styled.header`
  position: ${(props) => props.headerPosition};
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: ${(props) => props.theme.header.color};
  font-weight: ${(props) => props.theme.header.fontWeight};
  background-color: ${(props) => props.theme.colors.menuBackground};
  ${breakpoint("md")`
    background-color: transparent;
  `}
  &:after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: ${(props) => props.theme.header.background};
    transition: opacity 0.2s;
    opacity: ${(props) => (!props.collapse ? 1 : 0)};
    z-index: 0;
  }
  * {
    z-index: 10;
  }
  ${MenuToggle} {
    ${breakpoint("md")`
      ${(props) => (props.collapse ? "" : "display: none;")}
    `}
  }
`;

export const Icon = styled.span`
  display: inline-block;
  position: absolute;
  top: 2.75rem;
  left: calc(${(props) => props.theme.spacing.root} / 2);
  ${breakpoint("md")`
  position: static;
  padding: ${(props) =>
    props.collapse
      ? `calc(${props.theme.spacing.root} / 2)`
      : props.theme.spacing.root};
  `}
  .logo {
    ${(props) =>
      props.collapse
        ? `height: 2em;
        width: ${2 * 1}em;`
        : `height: 1.2em;
        width: ${1.2 * 9.3913}em;`}
  }
`;

export const MenuWrapper = styled.div`
  a {
    color: inherit;
    ${breakpoint("xs", "md")`
      font-size: ${(props) => props.theme.text.extraLarge.fontSize} !important;
    `}
    ${breakpoint("md")`
    ${(props) =>
      props.vertical &&
      `font-size: ${props.theme.text.extraLarge.fontSize} !important;
      letter-spacing: -0.0277em;
      font-weight: 500;
      line-height: 1;
      `}
      `}
  }
  ${SocialsNav} {
    display: none;
  }
`;

export const MenuBlock = styled.div`
  padding: ${(props) => props.theme.spacing.root};
  transition: width 0.2s;
  ${breakpoint("md")`
    ${(props) =>
      props.collapse &&
      `
      padding: calc(${props.theme.spacing.root} / 2);
      position: fixed;
      top: 0;
      right: 0;
      height: 100%;
      background-color: ${props.theme.colors.menuBackground};
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: calc(${props.theme.header.headerClosedWidth} + ${props.theme.spacing.root});
    `}
    ${(props) =>
      props.collapse &&
      props.active &&
      `
        width: 515px;
      `}
  `}
  ${breakpoint("xs", "md")`
    ${(props) =>
      props.active &&
      `
        height: 100vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        `}
    `}
  .header-menu {
    opacity: 1;
    text-align: center;
    display: flex;
    justify-content: center;
    transition: opacity 0.2s cubic-bezier(0.37, 0, 0.63, 1) 0.2s, width 0.3s cubic-bezier(0.37, 0, 0.63, 1);

    ${breakpoint("xs")`
        height: 0;
        overflow: hidden;
        ${(props) =>
          props.active &&
          `
          height: auto;
          `}
            ${(props) =>
              props.collapse &&
              `
                width: 100%;
            `}
        `}
    ${breakpoint("md")`
      height: auto;
                ${(props) =>
                  props.collapse &&
                  props.active &&
                  `
                  width: 100%;
                `}
            `}
            ${(props) =>
              props.collapse &&
              !props.active &&
              `
                    width: 0;
                    height: 0;
                    opacity: 0;
                    transform: translateX(500px);
                    transition: opacity 0 cubic-bezier(0.64, 0, 0.78, 0), width 0.3s cubic-bezier(0.37, 0, 0.63, 1);
                `}
                ${(props) =>
                  props.collapse &&
                  !props.active &&
                  `
                        width: 0;
                        opacity: 0;
                        transform: translateX(500px);
                        transition: opacity 0 cubic-bezier(0.64, 0, 0.78, 0), width 0.3s cubic-bezier(0.37, 0, 0.63, 1);
                    `}
`;
