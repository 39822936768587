import React, { useEffect, useState } from "react";
import WorkList from "../../components/WorkList";
import WorkSlider from "../../components/WorkSlider";
import { isBrowser } from "../../Utils";
import { WorkBlockLayout } from "./style";

const Work = ({ heading, settings, work, className, layout }) => {
  const getWidth = () =>
    isBrowser
      ? window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth
      : null;

  const [width, setWidth] = useState(getWidth());

  useEffect(() => {
    const resizeListener = () => {
      // change width from the state object
      setWidth(getWidth());
    };

    if (isBrowser) {
      setWidth(getWidth());
      window.addEventListener("resize", resizeListener);
      return () => {
        window.removeEventListener("resize", resizeListener);
      };
    }
    return null;
  }, []);
  const blockType = () => {
    if(isBrowser) {
      return layout === "list" && width > 767;
    }
    return true;
  }
  // const Block = layout === "list" ? WorkList : WorkSlider;
  
  return (
    <WorkBlockLayout
      className={className}
      heading={heading}
      settings={settings}
    >
      {blockType() ? <WorkList work={work} /> : <WorkSlider work={work} /> }
    </WorkBlockLayout>
  );
};
export default Work;
