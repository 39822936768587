import styled from 'styled-components';
import { Swiper} from "swiper/react";
import breakpoint from 'styled-components-breakpoint';

export const ClientSlider = styled(Swiper)`
        overflow: visible;
        .swiper-slide {
            height: auto;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            width: 98px;
            ${breakpoint('md')`
                width: 160px;
            `}
            ${breakpoint('xl')`
                width: 230px;
            `}
        }
        
`;