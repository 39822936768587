import React from "react";
import VideoEmbed from "../../components/VideoEmbed";
import {MediaBlockLayout} from './style'
import Image from "../../components/Image";

const Media = (props) => {
  const {
    heading,
    settings,
    className,
    mediaType,
    playText,
    videoUrl,
    poster,
    videoFile,
    image,
    aspectRatio
  } = props;
  if (mediaType === "video_embed") {
    return (
    <MediaBlockLayout heading={heading} settings={settings} className={className}>
      <VideoEmbed url={videoUrl} poster={poster}  aspectRatio={aspectRatio} />
    </MediaBlockLayout>)
  }

  if (mediaType === "video_file") {
    return (
    <MediaBlockLayout heading={heading} settings={settings} className={className}>
      {videoFile && <VideoEmbed url={videoFile.mediaItemUrl} poster={poster} playText={playText}  aspectRatio={aspectRatio} />}
    </MediaBlockLayout>)
  }

  return (
    <MediaBlockLayout heading={heading} settings={settings} className={className}>
      <Image image={image} className="media-image" />
    </MediaBlockLayout>
  );
};
export default Media;
