import styled from 'styled-components';
import BlockLayout from '../BlockLayout';
import { InnerBlock } from '../BlockLayout/style';

const handleLayout = width => {
    switch(width) {
        case 'alignFull':
            return `
                padding-left: 0;
                padding-right: 0;
                ${InnerBlock} {
                    padding-right: 0;
                }
                .media-image {
                    width: 100%;
                }
            `
        default :
            return ``;
    }
}

export const MediaBlockLayout = styled(BlockLayout)`
    ${props => handleLayout(props.settings?.width)};
`