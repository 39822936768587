import React from "react";
import { Helmet } from "react-helmet";
import OgImage from "../assets/images/ogImage.png";

const Entities = require("html-entities").AllHtmlEntities;

const entities = new Entities();

const Seo = ({
  title,
  metaDesc,
  opengraphDescription,
  opengraphTitle,
  opengraphImage,
  twitterTitle,
  twitterDescription,
  twitterImage,
}) => {
  return (
    <Helmet
      htmlAttributes={{ amp: true, lang: "en" }}
      defaultTitle={entities.decode(title)}
      title={entities.decode(title)}
      meta={[
        {
          name: "description",
          content: entities.decode(metaDesc),
        },
        {
          property: "og:title",
          content: entities.decode(opengraphTitle) || entities.decode(title),
        },
        {
          property: "og:description",
          content:
            entities.decode(opengraphDescription) || entities.decode(metaDesc),
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          name: "twitter:title",
          content: entities.decode(twitterTitle) || entities.decode(title),
        },
        {
          name: "twitter:description",
          content:
            entities.decode(twitterDescription) || entities.decode(metaDesc),
        },
        {
          property: "og:image",
          content: opengraphImage ? opengraphImage.sourceUrl : OgImage,
        },
        {
          property: "twitter:image",
          content: twitterImage ? twitterImage.sourceUrl : OgImage,
        },
      ]}
    />
  );
};

export default Seo;
