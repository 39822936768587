import styled from "styled-components";
import { map } from "styled-components-breakpoint";
import BlockLayout from "../BlockLayout";

export const BlockQuote = styled(BlockLayout)`
    padding-top: 20vh;
    &:after {
        content: '';
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 10%, ${props => props.theme.body.backgroundColor} 60%);
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`;

export const Quote = styled.blockquote`
    font-weight: ${props => props.theme.text.extraLarge.fontWeight };
    max-width: ${props => props.theme.widths.content};
    ${props => map(props.theme.widths.contentSizes, width => `max-width: ${width};`)}
    font-size: ${props => props.theme.text.extraLarge.fontSize};
    color: ${props => props.theme.colors.secondary};
    line-height: 1.17;
    margin-left: 0;
    margin-bottom: 0.6041666667em;
`

export const Cite = styled.cite`
    font-size: ${props => props.theme.text.small.fontSize};
    font-weight: ${props => props.theme.text.small.fontWeight};
    color: ${props => props.theme.colors.secondary};
    text-transform: uppercase;
    font-style: normal;
`;
