import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

export const IconLink = styled.a`
    display: inline-block;
    height: 1em;
    width: 1em;
    color: ${props => props.theme.colors.secondary};
    &:hover {
        opacity: 0.7;
    }
    svg {
        fill: currentColor;
    }
    ${breakpoint('xs', 'md')`
        height: 48px;
        width: 48px;
        padding: 0.5em;
    `}
`;
export const Menu = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    ${props => props.vertical && 'flex-direction: column;'}
    li {
        margin: 0;
        padding: 0;
        &:not(:first-child){
            ${props => props.vertical ? "margin-top" : "margin-left"}: 1rem;
        }
    }
`;

export const Nav = styled.nav(({align = 'flex-start'}) => `
    ${Menu} {
        justify-content: ${align};
    }
`);