import styled from 'styled-components';
import breakpoint, { map } from 'styled-components-breakpoint';

export const Nav = styled.nav``
export const Menu = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    ${breakpoint('md')`
        ${props => props.vertical ? 'flex-direction: column;' : 'flex-direction: row;'}
    `}
    
    li {
        margin: 0;
        padding: 0;
        &:not(:first-child){
            ${breakpoint('md')`
                ${props => props.vertical ? "margin-top" : "margin-left"}: 4rem;
            `}
        }
        a {
            display: inline-block;
            font-size: ${props => props.theme.text.small.fontSize};
            ${props => map(props.theme.text.small.fontSizes, size => `font-size: ${size};`)}
            &.current-menu-item {
                border-bottom: 1px solid currentColor;
            }
        }
    }
`;