import React from "react";
import PlayText from "../../components/PlayText";
import { StyledHero, VideoEmbed } from "./style";
import ReactPlayer from 'react-player/lazy'
import ModalConsumer from '../../Context/ModalContext'

const HeroVideo = ({heading, settings, playText, poster, className, videoFile, backgroundVideo, isEmbed, videoUrl}) => {
    const Background = backgroundVideo && <ReactPlayer url={backgroundVideo.mediaItemUrl} playing={true} loop={true} muted={true} playsinline={true} />;
    return <StyledHero heading={heading} pageTitle={true} className={className} settings={settings} backgroundImage={poster?.localFile} background={Background}>
        <ModalConsumer>
        {({ openModal, closeModal }) => {
            const ModalContent = <VideoEmbed>
                <ReactPlayer url={isEmbed ? videoUrl : videoFile ? videoFile.mediaItemUrl : null} height="100%" width="100%" className="react-player" playing={true} controls={true} muted={false} onEnded={() => closeModal()} playsinline={false} />
            </VideoEmbed>;
            return <>{playText && <PlayText onClick={() => openModal(ModalContent)}>{playText}</PlayText>}</>
        }}</ModalConsumer>
    </StyledHero>;
}
export default HeroVideo