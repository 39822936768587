import styled from "styled-components";
import breakpoint, { map } from "styled-components-breakpoint";
import { Nav, Menu } from "../Socials/style";

const spacer = "80px";

export const Footer = styled.footer`
  max-width: ${(props) =>
    `calc(${props.theme.widths.breakout} + (${props.theme.spacing.root} * 2))`};
  margin: auto;
  padding: ${(props) => `${spacer} ${props.theme.spacing.root}`};
  ${props => map(props.theme.blockPadding, padding => `padding: ${padding}px;`)}
  overflow: hidden;
  ${(props) =>
    map(
      props.theme.widths.breakoutSizes,
      (width) =>
        `max-width: calc(${width} + (${props.theme.spacing.root} * 2));`
    )}
  ${breakpoint("md")`
            padding-right: ${(props) =>
              `calc(${props.theme.header.headerClosedWidth} + ${props.theme.spacing.root}*2)`};
        `}
`;

export const Column = styled.div`
  text-align: center;
  width: 100%;
  ${Nav} {
    ${Menu} {
      ${breakpoint("xs", "md")`
            justify-content: center;
        `}
    }
  }
  ${breakpoint("md")`
        text-align: left;

    `}
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: -${(props) => (props.space ? props.space : spacer)};
  margin-right: -${(props) => (props.space ? props.space : spacer)};
  ${breakpoint("md")`
        flex-wrap: nowrap;
        justify-content: flex-start;
    `}
  ${Column} {
    ${breakpoint("md")`
        width: 50%;
    `}
    padding-left: ${(props) => (props.space ? props.space : spacer)};
    padding-right: ${(props) => (props.space ? props.space : spacer)};
    &.col-auto {
      width: auto;
    }
  }
`;

export const SubscribeWrapper = styled(Row)`
  align-items: flex-end;
  margin-bottom: 64px;
  h6 {
    margin-bottom: 0.5em;
    color: ${(props) => props.theme.colors.highlight};
  }
  p {
    margin: 0;
  }
`;

export const MenuWrapper = styled.div`
  margin-left: 1em;
  a {
    color: inherit;
    &:hover {
      opacity: 0.7;
    }
    ${(props) =>
      map(
        props.theme.text.extraSmall.fontSizes,
        (size) => `font-size: ${size} !important;`
      )}
  }
`;

export const Copyright = styled.div`
  ${(props) =>
    map(
      props.theme.text.extraSmall.fontSizes,
      (size) => `font-size: ${size} !important;`
    )}
`;
