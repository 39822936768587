import React from "react";
import UniversalLink from "../UniversalLink";
import { Nav, Menu as StyledMenu } from "./style";

const Menu = ({ menu, vertical = false }) => {
  return (
    <Nav aria-label="Horizontal" role="navigation">
      <StyledMenu vertical={vertical}>
        {menu.menuItems.nodes.map((menuItem, i) => {
          if(menuItem.parentId) {
            return null;
          }
          const path = menuItem?.connectedNode?.node?.uri ?? menuItem.url;
          const target = menuItem?.target ?? '';
          const itemId = "menu-item-" + menuItem.databaseId;
          return (
            <li id={itemId} key={i + menuItem.url} className={itemId}>
              <UniversalLink
                to={path}
                target={target}
                title={menuItem.label}
                activeClassName={"current-menu-item current_page_item"}
              >
                {menuItem.label}
              </UniversalLink>
            </li>
          );
        })}
      </StyledMenu>
    </Nav>
  );
};
export default Menu;
