import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Menu from "../Menu"
import { MenuWrapper } from "./style"

const FooterMenu = (props) => {
  const { wpMenu } = useStaticQuery(graphql`
    {
      wpMenu(locations: {eq: FOOTER_NAVIGATION}) {
        name
        menuItems {
          nodes {
            label
            url
            databaseId
            target
            connectedNode {
              node {
                ... on WpContentNode {
                  uri
                }
              }
            }
          }
        }
      }
    }
  `)

  if (!wpMenu?.menuItems?.nodes || wpMenu.menuItems.nodes === 0) return null

  return <MenuWrapper {...props}>
    <Menu menu={wpMenu} className='primary-menu' {...props}/>
  </MenuWrapper>
}

export default FooterMenu