import styled from 'styled-components';
import { map } from 'styled-components-breakpoint';

export const Modal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 100;
    background-color: ${props => props.theme.colors.modalBackground};
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const CloseToggle = styled.span`
    position: fixed;
    top: 0;
    right: 0;
    padding: 2rem;
    font-size: 40px;
    color: ${props => props.theme.colors.highlight};
    cursor: pointer;
    &:hover {
        opacity: 0.7;
    }
`;

export const ModalInner = styled.div`
    width: 100%;
    max-width: ${props => props.theme.widths.breakout};
    ${props => map(props.theme.widths.breakoutSizes, width => `max-width: ${width};`)}
    margin: auto;
`;