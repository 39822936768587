import React, { useRef, useState } from 'react';
import {SubscribeForm as StyledSubscribeForm, Input, SubmitButton} from './style'
import ArrowIcon from '../../assets/svgs/email_arrow-right.inline.svg';

const SubscribeForm = () => {
    const email = useRef(null);
    const [success, setSuccess ] = useState(false);
    const [message, setMessage ] = useState(false);
    const [loading, setLoading ] = useState(false);
    const handleSubmit = (e) => {
      e.preventDefault();
      setLoading(true);
      
      if(email.current.value){
        const data = new FormData(e.target);

        fetch('https://bolster.activehosted.com/proc.php', {
          method: 'POST',
          body: data,
          mode: 'no-cors',
        }).then(() => {
          setLoading(false);
            setSuccess(true);
        }).catch(() => {
          setMessage("There was an error. Please try again later");
        });
      }
    }

    if(success) {
      return <div>Thank you for subscribing</div>
    }
    return <StyledSubscribeForm method="POST" action="https://bolster.activehosted.com/proc.php" onSubmit={handleSubmit} loading={loading}>
    <input type="hidden" name="u" value="10" />
    <input type="hidden" name="f" value="10" />
    <input type="hidden" name="s" />
    <input type="hidden" name="c" value="0" />
    <input type="hidden" name="m" value="0" />
    <input type="hidden" name="act" value="sub" />
    <input type="hidden" name="v" value="2" />
    <Input ref={email} name="email" type='email' placeholder="email" required={true} />
    <SubmitButton type='submit' aria-label="submit"><ArrowIcon /></SubmitButton>
    {message && <p>{message}</p>}
  </StyledSubscribeForm>
}
export default SubscribeForm;