import styled from 'styled-components';
import { map } from 'styled-components-breakpoint';

export const Caption = styled.div`
    ${props => map(props.theme.text.small.fontSizes, size => `font-size: ${size};`)}
    ${props => map(props.theme.widths.breakoutSizes, width => `max-width: calc(${width} + (${props.theme.spacing.root} * 2));`)}
    margin: 1.375em auto;
    line-height: 1;
    font-weight: ${props => props.theme.text.small.fontWeight};
    letter-spacing: ${props => props.theme.text.small.letterSpacing};
    padding: 0 ${props => props.theme.spacing.root};
`;