import React from "react";
import Seo from "../components/Seo";
import { GlobalStyle } from "../theme/globalStyle";
import Footer from "../components/Footer";
import { ThemeProvider } from "styled-components";
import theme from "../theme/theme"
import Header from "../components/Header";
import { ModalProvider } from "../Context/ModalContext";
import Modal from "../components/Modal";

const PageLayout = ({ data, children, headerPosition = 'fixed' }) => {
  const { page } = data;
  const {
    seo,
  } = page;
  return (
    <ThemeProvider theme={theme.primary}>
      <ModalProvider>
      <GlobalStyle />
        <div className="body">
          <Header headerPosition={headerPosition} />
          <Seo {...seo} />
          {children}
          <Footer />
        </div>
        <Modal />
      </ModalProvider>
    </ThemeProvider>
  );
};

export default PageLayout;