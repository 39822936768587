import Image from '../Image/index';
import styled from 'styled-components'
import Label from '../Label';
import {map} from 'styled-components-breakpoint';

export const WorkImage = styled(Image)`
    border-top-right-radius: 40px;
    display: block;
    position: relative;
    z-index: 1;
`
export const WorkTitle = styled.h6`
    ${props => map(props.theme.text.small.fontSizes, size => `font-size: ${size};`)}
`;
export const ImageWrapper = styled.div`
    width: 100%;
    ${props => props.ratio && `padding-top: ${props.ratio * 100}%`};
    position: relative;
    margin-bottom: 0.8333em;
    ${WorkImage} {
        width: 100%;
        ${props => props.ratio && `
            position: absolute;
            bottom: 0;
            left: 0;
        `}
    }
`;
export const ClientLabel = styled(Label)`
    text-transform: uppercase;
    line-height: 1;
    margin-bottom: 0.375em;
    color: ${props=> props.theme.colors.secondary};
`