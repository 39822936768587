import React, { useState } from 'react';
import ReactPlayer from 'react-player'
import { getImage } from "gatsby-plugin-image";
import {VideoEmbed as StyledVideoEmbed, Poster, PlayingText } from './style'

const VideoEmbed = (props) => {
    const {poster, hidePlay = false, playing = false, aspectRatio = 0.5625 } = props;
    const [playingCheck, setPlaying] = useState(playing);
    const posterImage = poster ? getImage(poster.localFile) : false;
    
    return <StyledVideoEmbed onClick={() => setPlaying(!playingCheck)} aspectRatio={aspectRatio} playing={playing}>
        <ReactPlayer playing={playingCheck} height="100%" width="100%" className="react-player" onPlay={() => setPlaying(true)} onPause={() => setPlaying(false)} onEnded={() => setPlaying(false)} {...props} />
        {posterImage && <Poster playing={playingCheck} image={posterImage} />}
        {!hidePlay && <PlayingText onClick={() => setPlaying(!playingCheck)}>{playingCheck ? 'Stop' : props.playText ? props.playText : 'Play'}</PlayingText>}
    </StyledVideoEmbed>
}

export default VideoEmbed;