import { map } from 'styled-components-breakpoint';
import { createGlobalStyle } from "styled-components";
import { normalize } from "styled-normalize";

import "../assets/fonts/fonts.css";

export const GlobalStyle = createGlobalStyle`
    ${normalize}
    * {
        box-sizing: border-box;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; 
        -webkit-backface-visibility: hidden !important;
    }
    html {
        font-size: ${props => props.theme.body.fontSize};
    }
    
    body {
        background-color: ${props => props.theme.body.backgroundColor};
        color: ${props => props.theme.body.color};
        line-height: ${props => props.theme.body.lineHeight};
        font-family: ${props => props.theme.body.fontFamily};
        .body {
            font-weight: ${props => props.theme.text.default.fontWeight};
            ${props => map(props.theme.text.default.fontSizes, size => `font-size: ${size};`)}
        }
    }
    h1, h2, h3, h4, h5, h6 {
        margin-top: 0;
    }
    h1 {
        font-family: ${props => props.theme.headings.h1.fontFamily};
        color: ${props => props.theme.headings.h1.color};
        font-weight: ${props => props.theme.headings.h1.fontWeight};
        letter-spacing: ${props => props.theme.headings.h1.letterSpacing};
        margin-bottom: 0.5125em;
        line-height: ${props => props.theme.headings.h1.lineHeight};
        ${props => map(props.theme.headings.h1.fontSizes, size => `font-size: ${size};`)}
    }
    h2 {
        font-family: ${props => props.theme.headings.h2.fontFamily};
        color: ${props => props.theme.headings.h2.color};
        font-weight: ${props => props.theme.headings.h2.fontWeight};
        letter-spacing: ${props => props.theme.headings.h2.letterSpacing};
        line-height: ${props => props.theme.headings.h2.lineHeight};
        margin-bottom: 0.640625em;
        ${props => map(props.theme.headings.h2.fontSizes, size => `font-size: ${size};`)}
    }
    h3 {
        font-family: ${props => props.theme.headings.h3.fontFamily};
        color: ${props => props.theme.headings.h3.color};
        font-weight: ${props => props.theme.headings.h3.fontWeight};
        letter-spacing: ${props => props.theme.headings.h3.letterSpacing};
        line-height: ${props => props.theme.headings.h3.lineHeight};
        margin-bottom: 0.4821428571em;
        ${props => map(props.theme.headings.h3.fontSizes, size => `font-size: ${size};`)}
    }
    h4 {
        font-family: ${props => props.theme.headings.h4.fontFamily};
        color: ${props => props.theme.headings.h4.color};
        font-weight: ${props => props.theme.headings.h4.fontWeight};
        letter-spacing: ${props => props.theme.headings.h4.letterSpacing};
        line-height: ${props => props.theme.headings.h4.lineHeight};
        margin-bottom: 0.6666666667em;
        ${props => map(props.theme.headings.h4.fontSizes, size => `font-size: ${size};`)}
    }
    h5 {
        font-family: ${props => props.theme.headings.h5.fontFamily};
        color: ${props => props.theme.headings.h5.color};
        font-weight: ${props => props.theme.headings.h5.fontWeight};
        letter-spacing: ${props => props.theme.headings.h5.letterSpacing};
        line-height: ${props => props.theme.headings.h5.lineHeight};
        margin-bottom: 0.35em;
        ${props => map(props.theme.headings.h5.fontSizes, size => `font-size: ${size};`)}
    }
    h6 {
        font-family: ${props => props.theme.headings.h6.fontFamily};
        color: ${props => props.theme.headings.h6.color};
        font-weight: ${props => props.theme.headings.h6.fontWeight};
        letter-spacing: ${props => props.theme.headings.h4.letterSpacing};
        line-height: ${props => props.theme.headings.h6.lineHeight};
        margin-bottom: 0.53125em;
        ${props => map(props.theme.headings.h6.fontSizes, size => `font-size: ${size};`)}
    }
    p {
        margin-top: 0;
        margin-bottom: 1.375em;
    }
    .extraSmall {
        font-size: ${props => props.theme.text.extraSmall.fontSize};
        font-weight: ${props => props.theme.text.extraSmall.fontWeight};
        ${props => map(props.theme.text.extraSmall.fontSizes, size => `font-size: ${size};`)}
    }
    .small {
        font-size: ${props => props.theme.text.small.fontSize};
        font-weight: ${props => props.theme.text.small.fontWeight};
        ${props => map(props.theme.text.small.fontSizes, size => `font-size: ${size};`)}
    }
    .medium {
        font-size: ${props => props.theme.text.medium.fontSize};
        ${props => map(props.theme.text.medium.fontSizes, size => `font-size: ${size};`)}
    }
    .large {
        font-size: ${props => props.theme.text.large.fontSize};
        font-weight: ${props => props.theme.text.large.fontWeight};
        ${props => map(props.theme.text.large.fontSizes, size => `font-size: ${size};`)}
        color: ${props => props.theme.colors.secondary};
    }
    .extra-large {
        font-size: ${props => props.theme.text.extraLarge.fontSize};
        font-weight: ${props => props.theme.text.extraLarge.fontWeight};
        ${props => map(props.theme.text.extraLarge.fontSizes, size => `font-size: ${size};`)}
        color: ${props => props.theme.colors.secondary};
        margin-bottom: 0.6458333333em;
    }
    .extra-2large {
        font-size: ${props => props.theme.text.xxlLarge.fontSize};
        font-weight: ${props => props.theme.text.xxlLarge.fontWeight};
        ${props => map(props.theme.text.xxlLarge.fontSizes, size => `font-size: ${size};`)}
        color: ${props => props.theme.colors.secondary};
        letter-spacing: -0.027856em;
    }
    .extra-3large {
        font-size: ${props => props.theme.text.xxxlLarge.fontSize};
        font-weight: ${props => props.theme.text.xxxlLarge.fontWeight};
        ${props => map(props.theme.text.xxxlLarge.fontSizes, size => `font-size: ${size};`)}
        color: ${props => props.theme.colors.secondary};
        b, strong {
            color: ${props => props.theme.colors.highlight};
        }
    }
    .extra-4large {
        font-size: ${props => props.theme.text.xxxxlLarge.fontSize};
        font-weight: ${props => props.theme.text.xxxxlLarge.fontWeight};
        color: ${props => props.theme.colors.secondary};
        b, strong {
            color: ${props => props.theme.colors.highlight};
        }
    }
    a {
        text-decoration: none;
        color: inherit;
    }
    svg {
        height: 100%;
        width: 100%;
        fill: currentColor;
    }
    ul, ol {
        margin-top: 0.35em;
        margin-bottom: 1.375em;
        li {
            margin-bottom: 0.35em;
        }
    }
    table {
        width: 100%;
        border: 1px solid ${props => props.theme.colors.primary};
        border-collapse: collapse;
        margin-bottom: 1.375em;
        th, td {
            border: 1px solid ${props => props.theme.colors.primary};
            padding: 0.25em;
        }
    }
    img {
        max-width: 100%;
        height: auto;
    }
`;
