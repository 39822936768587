import React from "react";
import VideoEmbed from "../VideoEmbed";
import Image from "../Image";

const Media = (props) => {
  const {
    mediaType,
    playText,
    videoUrl,
    poster,
    videoFile,
    image,
    aspectRatio,
  } = props;
  if (mediaType === "video_embed") {
    return (
      <VideoEmbed url={videoUrl} poster={poster} aspectRatio={aspectRatio} />
    );
  }
  if (mediaType === "video_file") {
    let url = null;
    if (videoFile?.mediaItemUrl) {
      url = videoFile.mediaItemUrl;
    } else if (videoFile?.localfile?.publicURL) {
      url = videoFile.localfile.publicURL;
    } else {
      return <>{JSON.stringify(props)}</>;
    }
    return (
      <>
        {videoFile && (
          <VideoEmbed
            url={url}
            poster={poster}
            playText={playText}
            aspectRatio={aspectRatio}
          />
        )}
      </>
    );
  }
  return <Image image={image} className="media-image" />;
};
export default Media;
