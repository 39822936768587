import React from "react";
import RenderBlocks from "../renderBlocks";
import {Columns as StyledColumns} from './style'

const Columns = (props) => {
    const {heading, settings, columns, className, alignment, columnLayout} = props;
    const blocks = columns.map((block)=> {
      return <RenderBlocks blocks={block.blocks} />
    })
    return <StyledColumns className={className} heading={heading} settings={settings} alignment={alignment} columnLayout={columnLayout} columnCount={blocks.length}>
      {blocks}
    </StyledColumns>;
}
export default Columns