import React from "react";
import CONTENT_BLOCKS from "../constants/contentBlocks";
const RenderBlocks = ({blocks}) => {
    if(!blocks) return null;
     
    const Bl = blocks.map((blockData, index) => {
        const { __typename } = blockData;
        const ContentBlock = CONTENT_BLOCKS[__typename];
        if (!ContentBlock) {
          return <p key={index}>{__typename}</p>;
        }

        return <ContentBlock key={index} className={`block-${index} content-block `} {...blockData} />;
      })
    return <div>{Bl}</div>
}

export default RenderBlocks