import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";
import {map} from 'styled-components-breakpoint';

export const Client = styled.div`
    text-align: center;
`
export const ClientImage = styled(GatsbyImage)`
    border-radius: 50%;
    z-index: 1;
    position: relative;
    &:after {
        content: '';
        position: absolute;
        top: 15%;
        left: 0;
        height: 100%;
        width: 100%;
        background-image: linear-gradient(to bottom, rgba(16, 14, 15, 0), rgba(16, 14, 15, 0.32) 42%, ${props => props.theme.body.backgroundColor} 81%);
    }
`
export const ClientImageWrapper = styled.div``

export const ClientName = styled.h6`
    ${props => map(props.theme.text.default.fontSizes, fontSize => `font-size: ${fontSize};`)}
    line-height: 1;
`;