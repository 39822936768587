const colors = {
    bolsterBeige : '#fff9f2',
    offWhiteText : '#bdbdbd',
    jetBlack : '#000000',
    jetBlack75: '#000000C0',
    black: '#100e0f',
    offBlack: '#121314',
    bolsterBlack : '#121212',
    starkWhite : '#ffffff',
    offWhite : '#efefef',
}


const fontSizes = {
    h1: '8rem',
    h2: '6.4rem',
    h3: '5.6rem',
    xl: '4.8rem',
    l: '4rem',
    m: '3.2rem',
    default: '2.4rem',
    s: '1.6rem',
    xs: '1.2rem',
}

const spacing = {
    root: '4rem'
}
const fonts = {
    default: '"MoriGothic", sans-serif',
    heading: '"MoriGothic", sans-serif',
}


const theme = {
    primary: {
        breakpoints: { 
            xs: 0,
            sm: 576,
            md: 768,
            lg: 1400,
            xl: 2000
          },
          blockPadding: {
            xs: 16,
            sm: 20,
            md: 32,
            lg: 40,
          },
        spacing: spacing,
        widths: {
            content: '796px',
            contentSizes: {
                xs: '796px',
                xl: '1160px',
            },
            breakout: '1200px',
            breakoutSizes: {
                xs: '1200px',
                xl: '1920px',
            },
        },
        colors : {
            primary: colors.offWhiteText,
            secondary: colors.offWhite,
            tertairy: colors.offWhiteText,
            highlight: colors.starkWhite,
            highlightText: colors.bolsterBlack,
            secondaryHighlight: colors.offWhiteText,
            menuBackground: colors.jetBlack,
            modalBackground: colors.jetBlack75,
        },
        gradients: {
            blackToTop: `linear-gradient(to top, rgba(0, 0, 0, 0), ${colors.black})`,
            blackToBottom: `linear-gradient(to bottom, rgba(0, 0, 0, 0), ${colors.black} 85%)`,
        },
        body:{
            backgroundColor: colors.black,
            color: colors.offWhiteText,
            fontSize: '10px',
            lineHeight: '1.33',
            fontFamily: fonts.default,
        },
        header: {
            background: `linear-gradient(to top, rgba(0, 0, 0, 0), ${colors.black})`,
            color: colors.offWhite,
            fontWeight: 600,
            headerClosedWidth: '40px',
        },
        headings: {
            h1: {
                fontFamily: fonts.heading,
                color: colors.starkWhite,
                fontSize: fontSizes.h1,
                fontWeight: 700,
                lineHeight: 1.1,
                letterSpacing: '-0.02775em',
                fontSizes: {
                    xs: '4rem',
                    sm: '5.6rem',
                    lg: '8rem',
                    xl: '11.2rem',
                },
            },
            h2: {
                fontFamily: fonts.heading,
                color: colors.starkWhite,
                fontSize: fontSizes.h2,
                fontWeight: 700,
                lineHeight: 1.06,
                letterSpacing: '-0.02775em',
                fontSizes: {
                    xs: '3.2rem',
                    sm: '4rem',
                    lg: '6.4rem',
                    xl: '9rem',
                },
            },
            h3: {
                fontFamily: fonts.heading,
                color: colors.starkWhite,
                fontSize: fontSizes.h3,
                fontWeight: 700,
                lineHeight: 1.06,
                letterSpacing: '-0.02775em',
                fontSizes: {
                    xs: '2.8rem',
                    sm: '3.2rem',
                    lg: '5.6rem',
                    xl: '8rem',
                },
            },
            h4: {
                fontFamily: fonts.heading,
                color: colors.starkWhite,
                fontSize: fontSizes.xl,
                fontWeight: 500,
                lineHeight: 1.17,
                letterSpacing: '-0.02775em',
                fontSizes: {
                    xs: '2.6rem',
                    sm: '2.8rem',
                    lg: '4.8rem',
                    xl: '6.8rem',
                },
            },
            h5: {
                fontFamily: fonts.heading,
                color: 'inherit',
                fontSize: fontSizes.l,
                fontWeight: 500,
                lineHeight: 1.2,
                letterSpacing: '-0.02775em',
                fontSizes: {
                    xs: '2.4rem',
                    sm: '2.6rem',
                    lg: '4rem',
                    xl: '5.6rem',
                },
            },
            h6: {
                fontFamily: fonts.heading,
                color: 'inherit',
                fontSize: fontSizes.m,
                fontWeight: 500,
                lineHeight: 1.25,
                letterSpacing: '-0.02775em',
                fontSizes: {
                    xs: '2.4rem',
                    lg: '3.2rem',
                    xl: '4.4rem',
                },
            }
        },
        button: {
            default: {
                fontSize: fontSizes.default,
                color: colors.starkWhite,
                backgroundColor: colors.offBlack,
                fontWeight: 600,
            },
        },
        text: {
            extraSmall: {
                fontSize: fontSizes.xs,
                fontWeight: 300,
                fontSizes: {
                    xs: '0.9rem',
                    sm: '1rem',
                    lg: '1.2rem',
                    xl: '1.4rem',
                },
            },
            small :  {
                fontSize: fontSizes.s,
                fontWeight: 600,
                letterSpacing: '-0.0125em',
                fontSizes: {
                    xs: '1.4rem',
                    sm: '1.6rem',
                    lg: '2.4rem',
                }
            },
            default: {
                fontSize: fontSizes.default,
                fontWeight: 300,
                fontSizes: {
                    xs: '1.75rem',
                    sm: '2rem',
                    lg: '2.4rem',
                    xl: '3.2rem',
                },
            },
            medium: {
                fontSize: fontSizes.m,
                fontSizes: {
                    xs: '2rem',
                    sm: '2.4rem',
                    lg: '3.2rem',
                    xl: '4.4rem',
                },
            },
            large: {
                fontSize: fontSizes.l,
                fontWeight: 200,
                fontSizes: {
                    xs: '2.4rem',
                    sm: '2.6rem',
                    lg: '4rem',
                    xl: '5.6rem',
                },
            },
            extraLarge: {
                fontSize: fontSizes.xl,
                fontWeight: 200,
                fontSizes: {
                    xs: '2.6rem',
                    sm: '2.8rem',
                    lg: '4.8rem',
                    xl: '6.8rem',
                },
            },
            xxlLarge: {
                fontSize: fontSizes.h3,
                fontWeight: 200,
                fontSizes: {
                    xs: '2.8rem',
                    sm: '3.2rem',
                    lg: '5.6rem',
                    xl: '8rem',
                },
            },
            xxxlLarge: {
                fontSize: fontSizes.h2,
                fontWeight: 200,
                fontSizes: {
                    xs: '3.2rem',
                    sm: '4rem',
                    lg: '6.4rem',
                    xl: '9rem',
                },
            },
            xxxxlLarge: {
                fontSize: fontSizes.h1,
                fontWeight: 200,
                fontSizes: {
                    xs: '4rem',
                    sm: '5.6rem',
                    lg: '8rem',
                    xl: '11.2rem',
                },
            },
        }
    }
}

export default theme;