import styled from 'styled-components'

export const Input = styled.input`
    width: 100%;
    appearance: none;
    border: none;
    background-color: transparent;
    padding: 0.6667em 0;
    color: ${props => props.theme.colors.highlight};
    border-bottom: 1px solid ${props => props.theme.colors.highlight};
`;

export const SubmitButton = styled.button `
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    appearance: none;
    border: none;
    background-color: transparent;
    color: ${props => props.theme.colors.highlight};
    height: 1.5em;
    width: 1.5em;
`;

export const SubscribeForm = styled.form`
    position: relative;
    opacity: ${props => props.loading ? '0.3' : '1'};
    pointer-events: ${props => props.loading ? 'none' : 'all'};
`;