import AppleMusicIcon from '../assets/svgs/apple-music.inline.svg';
import EmailIcon from '../assets/svgs/email.inline.svg';
import FacebookIcon from '../assets/svgs/facebook.inline.svg';
import InstagramIcon from '../assets/svgs/instagram.inline.svg';
import LinktreeIcon from '../assets/svgs/linktree.inline.svg';
import SpotifyIcon from '../assets/svgs/spotify.inline.svg';
import TwitterIcon from '../assets/svgs/twitter.inline.svg';
import YoutubeIcon from '../assets/svgs/youtube.inline.svg';
import MediumIcon from '../assets/svgs/medium.inline.svg';

const Icons =  {
    apple_music : AppleMusicIcon,
    email : EmailIcon,
    facebook : FacebookIcon,
    instagram : InstagramIcon,
    linktree : LinktreeIcon,
    spotify : SpotifyIcon,
    twitter : TwitterIcon,
    youtube : YoutubeIcon,
    medium: MediumIcon,
};
export default Icons;