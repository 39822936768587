import React from "react";
import { getImage } from "gatsby-plugin-image"
import { Block, InnerBlock, BackgroundImage, Children, Title, BackgroundWrapper } from "./style";

const BlockLayout = ({heading, pageTitle = false, settings, children, backgroundImage = false, className, background, before}) => {
    const anchor = settings?.anchor;
    const image = backgroundImage ? getImage(backgroundImage) : false;
    return( <Block id={anchor} className={className} {...settings}>
        <InnerBlock className={settings?.width}>
            {before}
            {heading && <Title pageTitle={pageTitle} as={pageTitle ? 'h1' : 'h3'}>{heading}</Title>}
            {children && <Children>{children}</Children>}
        </InnerBlock>
        {backgroundImage && <BackgroundImage image={image} />}
        {background && <BackgroundWrapper>{background}</BackgroundWrapper>}
    </Block>)
}
export default BlockLayout