import styled from 'styled-components';
import UniversalLink from '../UniversalLink';

export const Button = styled(UniversalLink)`
    color: ${props => props.theme.button.default.color};
    display: inline-block;
    border: 1px solid ${props => props.theme.button.default.color};
    font-size: ${props => props.theme.button.default.fontSize};
    font-weight: ${props => props.theme.button.default.fontWeight};
    line-height: 1;
    border-radius: 1.5em;
    padding: 0.75em 1.375em;
    position: relative;
    z-index: 1;
    overflow: hidden;
    transition: 0.2s;
    &:after {
        content:'';
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        background-color: ${props => props.theme.button.default.color};
        height: 100%;
        width: 100%;
        border-radius: 1.5em;
        transition: 0.2s;
        transform: translateX(100%);
    }
    &:hover {
        color: ${props => props.theme.button.default.backgroundColor};
        &:after {
            transform: translateX(0);
        }
    }
`