import styled from 'styled-components';
import { Swiper} from "swiper/react";
import BlockLayout from '../BlockLayout';

export const InnerSlide = styled.div`
    box-shadow: 10px 10px 10px 0 rgba(0, 0, 0, 0.3);
    line-height: 0;
    position: relative;
    &:after {
        content: '';
        position: absolute;
        pointer-events: none;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: black;
        opacity: 0;
        transition: opacity 0.2s;
    }
`;
export const StyledBlockLayout = styled(BlockLayout)`
    margin-top: 40px;
    margin-bottom: 40px;
`;
export const GallerySlider = styled(Swiper)`
        overflow: visible;
        .swiper-slide {
            height: auto;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            &.swiper-slide-active {
                ${InnerSlide} {
                    &:after {
                        opacity: 0;
                    }
                }
            }
        }
        
`;
export const NavigationContainer = styled.div`
position: absolute;
pointer-events: none;
height: 100%;
width: 100vw;
top: 0;
left: 50%;
transform: translateX(-50%);
z-index: 10;
.swiper-button-next {
    position: absolute;
    display: block;
    height: 100%;
    width: 20rem;
    top: 0;
    right: 0;
    z-index: 100;
    pointer-events: auto;
    cursor: pointer;
    &:before {
        content: '\u2192';
        display: block;
        font-size: 4.8rem;
        position: absolute;
        top: 50%;
        right: 1em;
        transform: translateY(-50%);
        opacity: 0;
        transition: opacity 0.2s;
    }
    &:hover {
        &:before{
            opacity: 1;
        }
    }
}
.swiper-button-prev {
    position: absolute;
    display: block;
    height: 100%;
    width: 20rem;
    top: 0;
    left: 0;
    z-index: 100;
    pointer-events: auto;
    cursor: pointer;
    &:before {
        content: '\u2190';
        display: block;
        font-size: 4.8rem;
        position: absolute;
        top: 50%;
        left: 1em;
        transform: translateY(-50%);
        opacity: 0;
        transition: opacity 0.2s;
    }
    &:hover {
        &:before{
            opacity: 1;
        }
    }
}
`;